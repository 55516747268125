import {computed} from "vue";

import { createRouter, createWebHashHistory } from 'vue-router'

import store from '@/store/store'
import {ApiAuth} from "@/api/api.auth";
import {ApiReport} from "@/api/api.report";

const prevUrl = computed(() => store.getters.getPrevUrl)

const router = createRouter({
  history: createWebHashHistory() ,  // createWebHashHistory 해시모드, createWebHistory 히스토리 모드
  linkActiveClass: "on",
  routes: [
    {
      path: '/testChild',
      name: "testChild",
      meta: { layout : "layoutFull", bodyclass: "black_body", title: "WECARE"},
      component: () => import('@/views/testChild')
    },
    {
      path: '/testParents',
      name: "testParents",
      meta: { layout : "layoutFull", bodyclass: "black_body", title: "WECARE"},
      component: () => import('@/views/testParents')
    },
    {
      // 로그인
      path: '/Login',
      name: "Login",
      meta: { layout : "layoutFull", bodyclass: "black_body", title: "WECARE"},
      component: () => import('@/views/LogIn')
    },
    {
      // 로그인
      path: '/Login',
      name: "Login",
      meta: { layout : "layoutFull", bodyclass: "black_body", title: "WECARE"},
      component: () => import('@/views/LogIn')
    },
    {
      // 로그아웃
      path: '/LogOut',
      name: "LogOut",
      meta: { layout : "layoutFull", bodyclass: "black_body", title: "WECARE"},
      component: () => import('@/views/LogOut')
    },
    {
      // 회원가입 - step 1
      path: '/JoinStep01',
      name: "JoinStep01",
      meta: { layout : "layoutFull", bodyclass: "white_body", title: "WECARE"},
      component: () => import('@/views/JoinStep01')
    },
    {
      // 회원가입 - step 2
      path: '/JoinStep02',
      name: "JoinStep02",
      meta: { layout : "layoutFull", bodyclass: "white_body", title: "WECARE"},
      component: () => import('@/views/JoinStep02')
    },
    {
      // 회원가입 - step 3
      path: '/JoinStep03',
      name: "JoinStep03",
      meta: { layout : "layoutFull", bodyclass: "white_body", title: "WECARE"},
      component: () => import('@/views/JoinStep03')
    },
    {
      // 메인화면 - 의료진관리
      path: '/',
      alias : ['/DoctorMng','/'],
      name: "main",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/DoctorMng'),
      beforeEnter: (to, from, next) => {
        const adminInfo = store.getters.getAdminInfo
        if (adminInfo.admAuth === 'SUPER_ADMIN' || adminInfo.admAuth === 'HOSP_ADMIN') {
          next();
        } else {
          next('/PatientMng');
        }
      },
    },
    {
      // 환자관리
      path: '/PatientMng',
      name: "PatientMng",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/PatientMng')
    },
    {
      // 환자관리 - 외래 환자
      path: '/PatientMngToday',
      name: "PatientMngToday",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/PatientMngToday')
    },
    {
      // 환자관리 상세
      path: '/PatientMngDetail',
      name: "PatientMngDetail",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/PatientMngDetail')
    },
    {
      // 설문관리 - 개별 설문관리
      path: '/SurveyMng',
      name: "SurveyMng",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyMng')
    },
    {
      // 설문관리 - 개별 설문관리 설문생성
      path: '/SurveyMngReg',
      name: "SurveyMngReg",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyMngReg')
    },
    {
      // 설문관리 - 개별 설문관리 설문수정
      path: '/SurveyMngUpd',
      name: "SurveyMngUpd",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyMngUpd')
    },
    {
      // 설문관리 - 개별 설문관리 환자목록
      path: '/SurveyMngPatientList',
      name: "SurveyMngPatientList",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyMngPatientList')
    },
    {
      // 설문관리 - 개별 설문관리 미리보기
      path: '/SurveyPreview',
      name: "SurveyPreview",
      meta: { layout : "layoutFull", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyPreview')
    },
    {
      // 실제 설문 수행
      path: '/SurveyReal',
      name: "SurveyReal",
      meta: { layout : "layoutFull", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyReal')
    },
    {
      // 실제 설문 수행
      path: '/SurveyRealGroup',
      name: "SurveyRealGroup",
      meta: { layout : "layoutFull", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyRealGroup')
    },
    {
      // 실제 설문 수행
      path: '/SurveyRealResearch',
      name: "SurveyRealResearch",
      meta: { layout : "layoutFull", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyRealResearch')
    },
    {
      // 실제 설문 수행
      path: '/SurveyRealFinal',
      name: "SurveyRealFinal",
      meta: { layout : "layoutFull", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyRealFinal')
    },
    {
      // 설문관리 - 연구 설문관리
      path: '/SurveyMngRes',
      name: "SurveyMngRes",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyMngRes')
    },
    {
      // 설문관리 - 그룹 설문관리
      path: '/SurveyMngGrp',
      name: "SurveyMngGrp",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyMngGrp')
    },
    {
      // 설문관리 - 그룹 설문관리 환자목록
      path: '/SurveyMngGrpPatientList',
      name: "SurveyMngGrpPatientList",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyMngGrpPatientList')
    },
    {
      // 설문 결과
      path: '/SurveyResult',
      name: "SurveyResult",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyResult')
    },
    {
      // 설문 결과 보기 개별
      path: '/SurveyResultSingle',
      name: "SurveyResultSingle",
      meta: { layout : "layoutFull", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyResultSingle')
    },
    {
      // KOQUSS 결과 보기 개별
      path: '/SurveyKoqussResult',
      name: "SurveyKoqussResult",
      meta: { layout : "layoutFull", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyKoqussResult')
    },
    {
      // 설문 결과 보기 그룹
      path: '/SurveyResultGroup',
      name: "SurveyResultGroup",
      meta: { layout : "layoutFull", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/SurveyResultGroup')
    },
    {
      // 문의게시판
      path: '/QnABoard',
      name: "QnABoard",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/QnABoard')
    },
    {
      // 콘텐츠관리
      path: '/ContentsMng',
      name: "ContentsMng",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/ContentsMng')
    },
    {
      // 콘텐츠관리 - 등록
      path: '/ContentsMngReg',
      name: "ContentsMngReg",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/ContentsMngReg')
    },
    {
      // 콘텐츠프리셋관리
      path: '/ContentsPresetMng',
      name: "ContentsPresetMng",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/ContentsPresetMng')
    },
    {
      // 콘텐츠프리셋관리 - 등록
      path: '/ContentsPresetMngReg',
      name: "ContentsPresetMngReg",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/ContentsPresetMngReg')
    },
    {
      // FAQ관리
      path: '/FAQMng',
      name: "FAQMng",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/FAQMng')
    },
    {
      // FAQ관리 등록
      path: '/FAQMngReg',
      name: "FAQMngReg",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/FAQMngReg')
    },
    {
      // 쇼핑도움서비스
      path: '/ShoppingHelpService',
      name: "ShoppingHelpService",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/ShoppingHelpService')
    },
    {
      // 내정보관리
      path: '/MyInfoMng',
      name: "MyInfoMng",
      meta: { layout : "layoutContents", bodyclass: "", title: "WECARE"},
      component : () => import('@/views/MyInfoMng')
    },
    {
      // KOQUSS-40 CSV 출력
      path: '/getKoquss40Report',
      name: "getKoquss40Report",
      beforeEnter(to, from, next) {
        let params = {}
        ApiReport.getKoquss40Report(params).then(res => {
            next(false);
        })
      },
      component: null, // 이 경로는 컴포넌트를 렌더링하지 않습니다.
    },
    {
      // KOQUSS-33 CSV 출력
      path: '/getKoquss33Report',
      name: "getKoquss33Report",
      beforeEnter(to, from, next) {
        let params = {}
        ApiReport.getKoquss33Report(params).then(res => {
          next(false);
        })
      },
      component: null, // 이 경로는 컴포넌트를 렌더링하지 않습니다.
    },
    {
      // 404 에러 페이지
      path : '/ErrorPage404',
      name : 'ErrorPage404',
      meta: { layout : "layoutFull", bodyclass: "white_body", title: "WECARE"},
      component:() => import('@/views/ErrorPage404.vue')
    },
    {
      // 지정하지 않은 path 입력했을 경우 404로 이동
      path : '/:catchAll(.*)', // 모든 매치하지 않은 path
      redirect : '/ErrorPage404'
    }
    ]
})

export default router

const isLoggedIn = () => {

  return (localStorage.getItem('_cnu_token') === null) ? false : true
}

// 로그인 체크 하지 말아야될 router name..
const protectedRoutes = [
  "Login",
  "JoinStep01",
  "JoinStep02",
  "JoinStep03"
]

// 라우터 이동 전 로그인 체크해서 Login 페이지로 이동
router.beforeEach((to, from, next) => {
  // 모바일 메뉴 닫는다. store 설정 변경..
  //store.commit("setIsMobileMenu", false)

  // 로그인 처리.. 로그인 확인..
  const isProtected = protectedRoutes.includes(to.name)
  console.log(isProtected)
  console.log(isLoggedIn())
  if(!isProtected){
    if(!isLoggedIn()){
      next({
        path: '/Login'
      })
    } else {

      // 약관 재동의 페이지에서 url 직접 입력으로 다른 페이지 넘어 갈 때 막기
      if(prevUrl.value === 'joinStep01'){
        localStorage.removeItem("_cnu_token")
        store.commit('setAdminInfo', {})
        next({
          path: '/Login'
        })
      }else{
        next()
      }
    }
  } else {
    next()
  }

  if (to.meta.bodyclass) {
    document.body.className = to.meta.bodyclass;
  } else {
    document.body.className = '';
  }
  next();
})

// 라우터 이동후
router.afterEach((to) => {
  //store.commit('setPrevUrl', window.location.hash)
  let title = to.meta.title === undefined ? "WECARE" : to.meta.title
  document.title = title
})

